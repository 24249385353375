/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from "./routes/__root";
import { Route as ClientIdImport } from "./routes/$clientId";
import { Route as IndexImport } from "./routes/index";
import { Route as ClientIdIndexImport } from "./routes/$clientId/index";
import { Route as ClientIdSidecarIndexImport } from "./routes/$clientId/sidecar/index";
import { Route as ClientIdHostsIndexImport } from "./routes/$clientId/hosts/index";
import { Route as ClientIdFileLockerIndexImport } from "./routes/$clientId/file-locker/index";
import { Route as ClientIdBillingIndexImport } from "./routes/$clientId/billing/index";
import { Route as ClientIdSidecarCreateImport } from "./routes/$clientId/sidecar/create";
import { Route as ClientIdHostsHostIdImport } from "./routes/$clientId/hosts/$hostId";
import { Route as ClientIdHostsHostIdIndexImport } from "./routes/$clientId/hosts/$hostId/index";
import { Route as ClientIdHostsHostIdWebdirectAutoScalingIndexImport } from "./routes/$clientId/hosts/$hostId/webdirect-auto-scaling/index";
import { Route as ClientIdHostsHostIdVolumesIndexImport } from "./routes/$clientId/hosts/$hostId/volumes/index";
import { Route as ClientIdHostsHostIdSnapbackIndexImport } from "./routes/$clientId/hosts/$hostId/snapback/index";
import { Route as ClientIdHostsHostIdPrivateBackupIndexImport } from "./routes/$clientId/hosts/$hostId/private-backup/index";
import { Route as ClientIdHostsHostIdFmsIndexImport } from "./routes/$clientId/hosts/$hostId/fms/index";
import { Route as ClientIdHostsHostIdEventsIndexImport } from "./routes/$clientId/hosts/$hostId/events/index";
import { Route as ClientIdHostsHostIdDynamicDocketIndexImport } from "./routes/$clientId/hosts/$hostId/dynamic-docket/index";
import { Route as ClientIdHostsHostIdDisasterRecoveryIndexImport } from "./routes/$clientId/hosts/$hostId/disaster-recovery/index";

// Create/Update Routes

const ClientIdRoute = ClientIdImport.update({
  id: "/$clientId",
  path: "/$clientId",
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => rootRoute,
} as any);

const ClientIdIndexRoute = ClientIdIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => ClientIdRoute,
} as any);

const ClientIdSidecarIndexRoute = ClientIdSidecarIndexImport.update({
  id: "/sidecar/",
  path: "/sidecar/",
  getParentRoute: () => ClientIdRoute,
} as any);

const ClientIdHostsIndexRoute = ClientIdHostsIndexImport.update({
  id: "/hosts/",
  path: "/hosts/",
  getParentRoute: () => ClientIdRoute,
} as any);

const ClientIdFileLockerIndexRoute = ClientIdFileLockerIndexImport.update({
  id: "/file-locker/",
  path: "/file-locker/",
  getParentRoute: () => ClientIdRoute,
} as any);

const ClientIdBillingIndexRoute = ClientIdBillingIndexImport.update({
  id: "/billing/",
  path: "/billing/",
  getParentRoute: () => ClientIdRoute,
} as any);

const ClientIdSidecarCreateRoute = ClientIdSidecarCreateImport.update({
  id: "/sidecar/create",
  path: "/sidecar/create",
  getParentRoute: () => ClientIdRoute,
} as any);

const ClientIdHostsHostIdRoute = ClientIdHostsHostIdImport.update({
  id: "/hosts/$hostId",
  path: "/hosts/$hostId",
  getParentRoute: () => ClientIdRoute,
} as any);

const ClientIdHostsHostIdIndexRoute = ClientIdHostsHostIdIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => ClientIdHostsHostIdRoute,
} as any);

const ClientIdHostsHostIdWebdirectAutoScalingIndexRoute =
  ClientIdHostsHostIdWebdirectAutoScalingIndexImport.update({
    id: "/webdirect-auto-scaling/",
    path: "/webdirect-auto-scaling/",
    getParentRoute: () => ClientIdHostsHostIdRoute,
  } as any);

const ClientIdHostsHostIdVolumesIndexRoute =
  ClientIdHostsHostIdVolumesIndexImport.update({
    id: "/volumes/",
    path: "/volumes/",
    getParentRoute: () => ClientIdHostsHostIdRoute,
  } as any);

const ClientIdHostsHostIdSnapbackIndexRoute =
  ClientIdHostsHostIdSnapbackIndexImport.update({
    id: "/snapback/",
    path: "/snapback/",
    getParentRoute: () => ClientIdHostsHostIdRoute,
  } as any);

const ClientIdHostsHostIdPrivateBackupIndexRoute =
  ClientIdHostsHostIdPrivateBackupIndexImport.update({
    id: "/private-backup/",
    path: "/private-backup/",
    getParentRoute: () => ClientIdHostsHostIdRoute,
  } as any);

const ClientIdHostsHostIdFmsIndexRoute =
  ClientIdHostsHostIdFmsIndexImport.update({
    id: "/fms/",
    path: "/fms/",
    getParentRoute: () => ClientIdHostsHostIdRoute,
  } as any);

const ClientIdHostsHostIdEventsIndexRoute =
  ClientIdHostsHostIdEventsIndexImport.update({
    id: "/events/",
    path: "/events/",
    getParentRoute: () => ClientIdHostsHostIdRoute,
  } as any);

const ClientIdHostsHostIdDynamicDocketIndexRoute =
  ClientIdHostsHostIdDynamicDocketIndexImport.update({
    id: "/dynamic-docket/",
    path: "/dynamic-docket/",
    getParentRoute: () => ClientIdHostsHostIdRoute,
  } as any);

const ClientIdHostsHostIdDisasterRecoveryIndexRoute =
  ClientIdHostsHostIdDisasterRecoveryIndexImport.update({
    id: "/disaster-recovery/",
    path: "/disaster-recovery/",
    getParentRoute: () => ClientIdHostsHostIdRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      id: "/";
      path: "/";
      fullPath: "/";
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    "/$clientId": {
      id: "/$clientId";
      path: "/$clientId";
      fullPath: "/$clientId";
      preLoaderRoute: typeof ClientIdImport;
      parentRoute: typeof rootRoute;
    };
    "/$clientId/": {
      id: "/$clientId/";
      path: "/";
      fullPath: "/$clientId/";
      preLoaderRoute: typeof ClientIdIndexImport;
      parentRoute: typeof ClientIdImport;
    };
    "/$clientId/hosts/$hostId": {
      id: "/$clientId/hosts/$hostId";
      path: "/hosts/$hostId";
      fullPath: "/$clientId/hosts/$hostId";
      preLoaderRoute: typeof ClientIdHostsHostIdImport;
      parentRoute: typeof ClientIdImport;
    };
    "/$clientId/sidecar/create": {
      id: "/$clientId/sidecar/create";
      path: "/sidecar/create";
      fullPath: "/$clientId/sidecar/create";
      preLoaderRoute: typeof ClientIdSidecarCreateImport;
      parentRoute: typeof ClientIdImport;
    };
    "/$clientId/billing/": {
      id: "/$clientId/billing/";
      path: "/billing";
      fullPath: "/$clientId/billing";
      preLoaderRoute: typeof ClientIdBillingIndexImport;
      parentRoute: typeof ClientIdImport;
    };
    "/$clientId/file-locker/": {
      id: "/$clientId/file-locker/";
      path: "/file-locker";
      fullPath: "/$clientId/file-locker";
      preLoaderRoute: typeof ClientIdFileLockerIndexImport;
      parentRoute: typeof ClientIdImport;
    };
    "/$clientId/hosts/": {
      id: "/$clientId/hosts/";
      path: "/hosts";
      fullPath: "/$clientId/hosts";
      preLoaderRoute: typeof ClientIdHostsIndexImport;
      parentRoute: typeof ClientIdImport;
    };
    "/$clientId/sidecar/": {
      id: "/$clientId/sidecar/";
      path: "/sidecar";
      fullPath: "/$clientId/sidecar";
      preLoaderRoute: typeof ClientIdSidecarIndexImport;
      parentRoute: typeof ClientIdImport;
    };
    "/$clientId/hosts/$hostId/": {
      id: "/$clientId/hosts/$hostId/";
      path: "/";
      fullPath: "/$clientId/hosts/$hostId/";
      preLoaderRoute: typeof ClientIdHostsHostIdIndexImport;
      parentRoute: typeof ClientIdHostsHostIdImport;
    };
    "/$clientId/hosts/$hostId/disaster-recovery/": {
      id: "/$clientId/hosts/$hostId/disaster-recovery/";
      path: "/disaster-recovery";
      fullPath: "/$clientId/hosts/$hostId/disaster-recovery";
      preLoaderRoute: typeof ClientIdHostsHostIdDisasterRecoveryIndexImport;
      parentRoute: typeof ClientIdHostsHostIdImport;
    };
    "/$clientId/hosts/$hostId/dynamic-docket/": {
      id: "/$clientId/hosts/$hostId/dynamic-docket/";
      path: "/dynamic-docket";
      fullPath: "/$clientId/hosts/$hostId/dynamic-docket";
      preLoaderRoute: typeof ClientIdHostsHostIdDynamicDocketIndexImport;
      parentRoute: typeof ClientIdHostsHostIdImport;
    };
    "/$clientId/hosts/$hostId/events/": {
      id: "/$clientId/hosts/$hostId/events/";
      path: "/events";
      fullPath: "/$clientId/hosts/$hostId/events";
      preLoaderRoute: typeof ClientIdHostsHostIdEventsIndexImport;
      parentRoute: typeof ClientIdHostsHostIdImport;
    };
    "/$clientId/hosts/$hostId/fms/": {
      id: "/$clientId/hosts/$hostId/fms/";
      path: "/fms";
      fullPath: "/$clientId/hosts/$hostId/fms";
      preLoaderRoute: typeof ClientIdHostsHostIdFmsIndexImport;
      parentRoute: typeof ClientIdHostsHostIdImport;
    };
    "/$clientId/hosts/$hostId/private-backup/": {
      id: "/$clientId/hosts/$hostId/private-backup/";
      path: "/private-backup";
      fullPath: "/$clientId/hosts/$hostId/private-backup";
      preLoaderRoute: typeof ClientIdHostsHostIdPrivateBackupIndexImport;
      parentRoute: typeof ClientIdHostsHostIdImport;
    };
    "/$clientId/hosts/$hostId/snapback/": {
      id: "/$clientId/hosts/$hostId/snapback/";
      path: "/snapback";
      fullPath: "/$clientId/hosts/$hostId/snapback";
      preLoaderRoute: typeof ClientIdHostsHostIdSnapbackIndexImport;
      parentRoute: typeof ClientIdHostsHostIdImport;
    };
    "/$clientId/hosts/$hostId/volumes/": {
      id: "/$clientId/hosts/$hostId/volumes/";
      path: "/volumes";
      fullPath: "/$clientId/hosts/$hostId/volumes";
      preLoaderRoute: typeof ClientIdHostsHostIdVolumesIndexImport;
      parentRoute: typeof ClientIdHostsHostIdImport;
    };
    "/$clientId/hosts/$hostId/webdirect-auto-scaling/": {
      id: "/$clientId/hosts/$hostId/webdirect-auto-scaling/";
      path: "/webdirect-auto-scaling";
      fullPath: "/$clientId/hosts/$hostId/webdirect-auto-scaling";
      preLoaderRoute: typeof ClientIdHostsHostIdWebdirectAutoScalingIndexImport;
      parentRoute: typeof ClientIdHostsHostIdImport;
    };
  }
}

// Create and export the route tree

interface ClientIdHostsHostIdRouteChildren {
  ClientIdHostsHostIdIndexRoute: typeof ClientIdHostsHostIdIndexRoute;
  ClientIdHostsHostIdDisasterRecoveryIndexRoute: typeof ClientIdHostsHostIdDisasterRecoveryIndexRoute;
  ClientIdHostsHostIdDynamicDocketIndexRoute: typeof ClientIdHostsHostIdDynamicDocketIndexRoute;
  ClientIdHostsHostIdEventsIndexRoute: typeof ClientIdHostsHostIdEventsIndexRoute;
  ClientIdHostsHostIdFmsIndexRoute: typeof ClientIdHostsHostIdFmsIndexRoute;
  ClientIdHostsHostIdPrivateBackupIndexRoute: typeof ClientIdHostsHostIdPrivateBackupIndexRoute;
  ClientIdHostsHostIdSnapbackIndexRoute: typeof ClientIdHostsHostIdSnapbackIndexRoute;
  ClientIdHostsHostIdVolumesIndexRoute: typeof ClientIdHostsHostIdVolumesIndexRoute;
  ClientIdHostsHostIdWebdirectAutoScalingIndexRoute: typeof ClientIdHostsHostIdWebdirectAutoScalingIndexRoute;
}

const ClientIdHostsHostIdRouteChildren: ClientIdHostsHostIdRouteChildren = {
  ClientIdHostsHostIdIndexRoute: ClientIdHostsHostIdIndexRoute,
  ClientIdHostsHostIdDisasterRecoveryIndexRoute:
    ClientIdHostsHostIdDisasterRecoveryIndexRoute,
  ClientIdHostsHostIdDynamicDocketIndexRoute:
    ClientIdHostsHostIdDynamicDocketIndexRoute,
  ClientIdHostsHostIdEventsIndexRoute: ClientIdHostsHostIdEventsIndexRoute,
  ClientIdHostsHostIdFmsIndexRoute: ClientIdHostsHostIdFmsIndexRoute,
  ClientIdHostsHostIdPrivateBackupIndexRoute:
    ClientIdHostsHostIdPrivateBackupIndexRoute,
  ClientIdHostsHostIdSnapbackIndexRoute: ClientIdHostsHostIdSnapbackIndexRoute,
  ClientIdHostsHostIdVolumesIndexRoute: ClientIdHostsHostIdVolumesIndexRoute,
  ClientIdHostsHostIdWebdirectAutoScalingIndexRoute:
    ClientIdHostsHostIdWebdirectAutoScalingIndexRoute,
};

const ClientIdHostsHostIdRouteWithChildren =
  ClientIdHostsHostIdRoute._addFileChildren(ClientIdHostsHostIdRouteChildren);

interface ClientIdRouteChildren {
  ClientIdIndexRoute: typeof ClientIdIndexRoute;
  ClientIdHostsHostIdRoute: typeof ClientIdHostsHostIdRouteWithChildren;
  ClientIdSidecarCreateRoute: typeof ClientIdSidecarCreateRoute;
  ClientIdBillingIndexRoute: typeof ClientIdBillingIndexRoute;
  ClientIdFileLockerIndexRoute: typeof ClientIdFileLockerIndexRoute;
  ClientIdHostsIndexRoute: typeof ClientIdHostsIndexRoute;
  ClientIdSidecarIndexRoute: typeof ClientIdSidecarIndexRoute;
}

const ClientIdRouteChildren: ClientIdRouteChildren = {
  ClientIdIndexRoute: ClientIdIndexRoute,
  ClientIdHostsHostIdRoute: ClientIdHostsHostIdRouteWithChildren,
  ClientIdSidecarCreateRoute: ClientIdSidecarCreateRoute,
  ClientIdBillingIndexRoute: ClientIdBillingIndexRoute,
  ClientIdFileLockerIndexRoute: ClientIdFileLockerIndexRoute,
  ClientIdHostsIndexRoute: ClientIdHostsIndexRoute,
  ClientIdSidecarIndexRoute: ClientIdSidecarIndexRoute,
};

const ClientIdRouteWithChildren = ClientIdRoute._addFileChildren(
  ClientIdRouteChildren,
);

export interface FileRoutesByFullPath {
  "/": typeof IndexRoute;
  "/$clientId": typeof ClientIdRouteWithChildren;
  "/$clientId/": typeof ClientIdIndexRoute;
  "/$clientId/hosts/$hostId": typeof ClientIdHostsHostIdRouteWithChildren;
  "/$clientId/sidecar/create": typeof ClientIdSidecarCreateRoute;
  "/$clientId/billing": typeof ClientIdBillingIndexRoute;
  "/$clientId/file-locker": typeof ClientIdFileLockerIndexRoute;
  "/$clientId/hosts": typeof ClientIdHostsIndexRoute;
  "/$clientId/sidecar": typeof ClientIdSidecarIndexRoute;
  "/$clientId/hosts/$hostId/": typeof ClientIdHostsHostIdIndexRoute;
  "/$clientId/hosts/$hostId/disaster-recovery": typeof ClientIdHostsHostIdDisasterRecoveryIndexRoute;
  "/$clientId/hosts/$hostId/dynamic-docket": typeof ClientIdHostsHostIdDynamicDocketIndexRoute;
  "/$clientId/hosts/$hostId/events": typeof ClientIdHostsHostIdEventsIndexRoute;
  "/$clientId/hosts/$hostId/fms": typeof ClientIdHostsHostIdFmsIndexRoute;
  "/$clientId/hosts/$hostId/private-backup": typeof ClientIdHostsHostIdPrivateBackupIndexRoute;
  "/$clientId/hosts/$hostId/snapback": typeof ClientIdHostsHostIdSnapbackIndexRoute;
  "/$clientId/hosts/$hostId/volumes": typeof ClientIdHostsHostIdVolumesIndexRoute;
  "/$clientId/hosts/$hostId/webdirect-auto-scaling": typeof ClientIdHostsHostIdWebdirectAutoScalingIndexRoute;
}

export interface FileRoutesByTo {
  "/": typeof IndexRoute;
  "/$clientId": typeof ClientIdIndexRoute;
  "/$clientId/sidecar/create": typeof ClientIdSidecarCreateRoute;
  "/$clientId/billing": typeof ClientIdBillingIndexRoute;
  "/$clientId/file-locker": typeof ClientIdFileLockerIndexRoute;
  "/$clientId/hosts": typeof ClientIdHostsIndexRoute;
  "/$clientId/sidecar": typeof ClientIdSidecarIndexRoute;
  "/$clientId/hosts/$hostId": typeof ClientIdHostsHostIdIndexRoute;
  "/$clientId/hosts/$hostId/disaster-recovery": typeof ClientIdHostsHostIdDisasterRecoveryIndexRoute;
  "/$clientId/hosts/$hostId/dynamic-docket": typeof ClientIdHostsHostIdDynamicDocketIndexRoute;
  "/$clientId/hosts/$hostId/events": typeof ClientIdHostsHostIdEventsIndexRoute;
  "/$clientId/hosts/$hostId/fms": typeof ClientIdHostsHostIdFmsIndexRoute;
  "/$clientId/hosts/$hostId/private-backup": typeof ClientIdHostsHostIdPrivateBackupIndexRoute;
  "/$clientId/hosts/$hostId/snapback": typeof ClientIdHostsHostIdSnapbackIndexRoute;
  "/$clientId/hosts/$hostId/volumes": typeof ClientIdHostsHostIdVolumesIndexRoute;
  "/$clientId/hosts/$hostId/webdirect-auto-scaling": typeof ClientIdHostsHostIdWebdirectAutoScalingIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  "/": typeof IndexRoute;
  "/$clientId": typeof ClientIdRouteWithChildren;
  "/$clientId/": typeof ClientIdIndexRoute;
  "/$clientId/hosts/$hostId": typeof ClientIdHostsHostIdRouteWithChildren;
  "/$clientId/sidecar/create": typeof ClientIdSidecarCreateRoute;
  "/$clientId/billing/": typeof ClientIdBillingIndexRoute;
  "/$clientId/file-locker/": typeof ClientIdFileLockerIndexRoute;
  "/$clientId/hosts/": typeof ClientIdHostsIndexRoute;
  "/$clientId/sidecar/": typeof ClientIdSidecarIndexRoute;
  "/$clientId/hosts/$hostId/": typeof ClientIdHostsHostIdIndexRoute;
  "/$clientId/hosts/$hostId/disaster-recovery/": typeof ClientIdHostsHostIdDisasterRecoveryIndexRoute;
  "/$clientId/hosts/$hostId/dynamic-docket/": typeof ClientIdHostsHostIdDynamicDocketIndexRoute;
  "/$clientId/hosts/$hostId/events/": typeof ClientIdHostsHostIdEventsIndexRoute;
  "/$clientId/hosts/$hostId/fms/": typeof ClientIdHostsHostIdFmsIndexRoute;
  "/$clientId/hosts/$hostId/private-backup/": typeof ClientIdHostsHostIdPrivateBackupIndexRoute;
  "/$clientId/hosts/$hostId/snapback/": typeof ClientIdHostsHostIdSnapbackIndexRoute;
  "/$clientId/hosts/$hostId/volumes/": typeof ClientIdHostsHostIdVolumesIndexRoute;
  "/$clientId/hosts/$hostId/webdirect-auto-scaling/": typeof ClientIdHostsHostIdWebdirectAutoScalingIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | "/"
    | "/$clientId"
    | "/$clientId/"
    | "/$clientId/hosts/$hostId"
    | "/$clientId/sidecar/create"
    | "/$clientId/billing"
    | "/$clientId/file-locker"
    | "/$clientId/hosts"
    | "/$clientId/sidecar"
    | "/$clientId/hosts/$hostId/"
    | "/$clientId/hosts/$hostId/disaster-recovery"
    | "/$clientId/hosts/$hostId/dynamic-docket"
    | "/$clientId/hosts/$hostId/events"
    | "/$clientId/hosts/$hostId/fms"
    | "/$clientId/hosts/$hostId/private-backup"
    | "/$clientId/hosts/$hostId/snapback"
    | "/$clientId/hosts/$hostId/volumes"
    | "/$clientId/hosts/$hostId/webdirect-auto-scaling";
  fileRoutesByTo: FileRoutesByTo;
  to:
    | "/"
    | "/$clientId"
    | "/$clientId/sidecar/create"
    | "/$clientId/billing"
    | "/$clientId/file-locker"
    | "/$clientId/hosts"
    | "/$clientId/sidecar"
    | "/$clientId/hosts/$hostId"
    | "/$clientId/hosts/$hostId/disaster-recovery"
    | "/$clientId/hosts/$hostId/dynamic-docket"
    | "/$clientId/hosts/$hostId/events"
    | "/$clientId/hosts/$hostId/fms"
    | "/$clientId/hosts/$hostId/private-backup"
    | "/$clientId/hosts/$hostId/snapback"
    | "/$clientId/hosts/$hostId/volumes"
    | "/$clientId/hosts/$hostId/webdirect-auto-scaling";
  id:
    | "__root__"
    | "/"
    | "/$clientId"
    | "/$clientId/"
    | "/$clientId/hosts/$hostId"
    | "/$clientId/sidecar/create"
    | "/$clientId/billing/"
    | "/$clientId/file-locker/"
    | "/$clientId/hosts/"
    | "/$clientId/sidecar/"
    | "/$clientId/hosts/$hostId/"
    | "/$clientId/hosts/$hostId/disaster-recovery/"
    | "/$clientId/hosts/$hostId/dynamic-docket/"
    | "/$clientId/hosts/$hostId/events/"
    | "/$clientId/hosts/$hostId/fms/"
    | "/$clientId/hosts/$hostId/private-backup/"
    | "/$clientId/hosts/$hostId/snapback/"
    | "/$clientId/hosts/$hostId/volumes/"
    | "/$clientId/hosts/$hostId/webdirect-auto-scaling/";
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  ClientIdRoute: typeof ClientIdRouteWithChildren;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ClientIdRoute: ClientIdRouteWithChildren,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/$clientId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/$clientId": {
      "filePath": "$clientId.tsx",
      "children": [
        "/$clientId/",
        "/$clientId/hosts/$hostId",
        "/$clientId/sidecar/create",
        "/$clientId/billing/",
        "/$clientId/file-locker/",
        "/$clientId/hosts/",
        "/$clientId/sidecar/"
      ]
    },
    "/$clientId/": {
      "filePath": "$clientId/index.tsx",
      "parent": "/$clientId"
    },
    "/$clientId/hosts/$hostId": {
      "filePath": "$clientId/hosts/$hostId.tsx",
      "parent": "/$clientId",
      "children": [
        "/$clientId/hosts/$hostId/",
        "/$clientId/hosts/$hostId/disaster-recovery/",
        "/$clientId/hosts/$hostId/dynamic-docket/",
        "/$clientId/hosts/$hostId/events/",
        "/$clientId/hosts/$hostId/fms/",
        "/$clientId/hosts/$hostId/private-backup/",
        "/$clientId/hosts/$hostId/snapback/",
        "/$clientId/hosts/$hostId/volumes/",
        "/$clientId/hosts/$hostId/webdirect-auto-scaling/"
      ]
    },
    "/$clientId/sidecar/create": {
      "filePath": "$clientId/sidecar/create.tsx",
      "parent": "/$clientId"
    },
    "/$clientId/billing/": {
      "filePath": "$clientId/billing/index.tsx",
      "parent": "/$clientId"
    },
    "/$clientId/file-locker/": {
      "filePath": "$clientId/file-locker/index.tsx",
      "parent": "/$clientId"
    },
    "/$clientId/hosts/": {
      "filePath": "$clientId/hosts/index.tsx",
      "parent": "/$clientId"
    },
    "/$clientId/sidecar/": {
      "filePath": "$clientId/sidecar/index.tsx",
      "parent": "/$clientId"
    },
    "/$clientId/hosts/$hostId/": {
      "filePath": "$clientId/hosts/$hostId/index.tsx",
      "parent": "/$clientId/hosts/$hostId"
    },
    "/$clientId/hosts/$hostId/disaster-recovery/": {
      "filePath": "$clientId/hosts/$hostId/disaster-recovery/index.tsx",
      "parent": "/$clientId/hosts/$hostId"
    },
    "/$clientId/hosts/$hostId/dynamic-docket/": {
      "filePath": "$clientId/hosts/$hostId/dynamic-docket/index.tsx",
      "parent": "/$clientId/hosts/$hostId"
    },
    "/$clientId/hosts/$hostId/events/": {
      "filePath": "$clientId/hosts/$hostId/events/index.tsx",
      "parent": "/$clientId/hosts/$hostId"
    },
    "/$clientId/hosts/$hostId/fms/": {
      "filePath": "$clientId/hosts/$hostId/fms/index.tsx",
      "parent": "/$clientId/hosts/$hostId"
    },
    "/$clientId/hosts/$hostId/private-backup/": {
      "filePath": "$clientId/hosts/$hostId/private-backup/index.tsx",
      "parent": "/$clientId/hosts/$hostId"
    },
    "/$clientId/hosts/$hostId/snapback/": {
      "filePath": "$clientId/hosts/$hostId/snapback/index.tsx",
      "parent": "/$clientId/hosts/$hostId"
    },
    "/$clientId/hosts/$hostId/volumes/": {
      "filePath": "$clientId/hosts/$hostId/volumes/index.tsx",
      "parent": "/$clientId/hosts/$hostId"
    },
    "/$clientId/hosts/$hostId/webdirect-auto-scaling/": {
      "filePath": "$clientId/hosts/$hostId/webdirect-auto-scaling/index.tsx",
      "parent": "/$clientId/hosts/$hostId"
    }
  }
}
ROUTE_MANIFEST_END */
