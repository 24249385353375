import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, FormControl, FormHelperText, InputLabel, styled } from "@mui/material";
import NotchedOutline from "@mui/material/OutlinedInput/NotchedOutline";
import { type CoreFileOptions, fileOpen } from "browser-fs-access";
import humanFormat from "human-format";
import { useSnackbar } from "notistack";
import { type ReactNode, useRef, useState } from "react";
import {
    type Control,
    type FieldPathByValue,
    type FieldValues,
    useController,
} from "react-hook-form";
import { z } from "zod";
import UploadedElements from "./UploadedElements.tsx";
import UploadingElements from "./UploadingElements.tsx";
import type { ProgressState, StartUploadMessage, UploadStatusMessage } from "./upload-worker.ts";

export const fileUploadSchema = z.object({
    key: z.string(),
    filename: z.string(),
    size: z.number(),
    md5Hash: z.string(),
});

export type FileUpload = z.output<typeof fileUploadSchema>;

type IndeterminateProgress = {
    type: "indeterminate";
};

type DeterminateProgress = {
    type: "determinate";
    current: number;
    total: number;
};

export type UploadStatus = {
    filename: string;
    size: number;
    state: ProgressState;
    progress: IndeterminateProgress | DeterminateProgress;
};

const NotchedInputOutline = styled(NotchedOutline)(({ theme }) => ({
    borderColor: "rgba(255, 255, 255, 0.23)",
    ...theme.applyStyles("light", {
        borderColor: "rgba(0, 0, 0, 0.23)",
    }),
}));

export type Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPathByValue<TFieldValues, FileUpload> = FieldPathByValue<
        TFieldValues,
        FileUpload
    >,
> = {
    control: Control<TFieldValues>;
    name: TName;
    label: string;
    createSignedPostPath: string;
    maxSize: number;
    required?: boolean;
    fileOptions?: CoreFileOptions;
};

const FileUploadField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPathByValue<TFieldValues, FileUpload> = FieldPathByValue<
        TFieldValues,
        FileUpload
    >,
>({
    control,
    name,
    label,
    createSignedPostPath,
    maxSize,
    required,
    fileOptions,
}: Props<TFieldValues, TName>): ReactNode => {
    const { field, fieldState } = useController({ control, name });
    const [uploadStatus, setUploadStatus] = useState<UploadStatus | null>(null);
    const uploadWorker = useRef<Worker>(undefined);
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const { enqueueSnackbar } = useSnackbar();

    const handleCancel = () => {
        setUploadStatus(null);

        if (uploadWorker.current) {
            uploadWorker.current.terminate();
        }
    };

    const handleSelect = async () => {
        const file = await fileOpen(fileOptions);

        if (file.size > maxSize) {
            enqueueSnackbar(
                `Selected file is larger than ${humanFormat(maxSize, { scale: "binary", unit: "B" })}`,
                { variant: "error" },
            );
            return;
        }

        setUploadStatus({
            filename: file.name,
            size: file.size,
            state: "calculating_checksum",
            progress: {
                type: "determinate",
                current: 0,
                total: file.size,
            },
        });

        let accessToken: string;

        try {
            accessToken = await getAccessTokenSilently();
        } catch {
            await loginWithRedirect({
                appState: {
                    returnTo: window.location.pathname,
                },
            });
            return;
        }

        const worker = new Worker(new URL("./upload-worker.ts", import.meta.url), {
            type: "module",
        });

        worker.addEventListener("message", (event: MessageEvent<UploadStatusMessage>) => {
            const message = event.data;

            switch (message.type) {
                case "progress": {
                    setUploadStatus({
                        filename: file.name,
                        size: file.size,
                        state: message.state,
                        progress: {
                            type: "determinate",
                            current: message.current,
                            total: message.total,
                        },
                    });
                    break;
                }

                case "complete": {
                    field.onChange({
                        key: message.objectKey,
                        filename: file.name,
                        size: file.size,
                        md5Hash: message.md5Hash,
                    });
                    setUploadStatus(null);
                    break;
                }

                case "error": {
                    enqueueSnackbar(message.message, { variant: "error" });
                    setUploadStatus(null);
                    break;
                }
            }
        });

        worker.postMessage({
            file,
            accessToken,
            createSignedPostPath,
        } satisfies StartUploadMessage);
        uploadWorker.current = worker;
    };

    let elements: ReactNode;

    if (field.value) {
        elements = (
            <UploadedElements
                value={field.value}
                onDelete={() => {
                    field.onChange(null);
                }}
            />
        );
    } else if (uploadStatus) {
        elements = <UploadingElements uploadStatus={uploadStatus} onCancel={handleCancel} />;
    } else {
        elements = (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexGrow: 1,
                }}
            >
                <Button onClick={handleSelect} disabled={false} variant="contained">
                    Select file
                </Button>
            </Box>
        );
    }
    let labelText: ReactNode = label;
    if (required) {
        labelText = <>{label}&thinsp;*</>;
    }
    return (
        <FormControl error={Boolean(fieldState.error)}>
            <InputLabel shrink>{labelText}</InputLabel>
            <Box
                sx={{
                    position: "relative",
                    display: "flex",
                    borderRadius: "4px",
                    height: "80px",
                    alignItems: "center",
                }}
            >
                {elements}
                <NotchedInputOutline
                    notched
                    label={labelText}
                    sx={{
                        borderColor: fieldState.error
                            ? (theme) => theme.palette.error.main
                            : undefined,
                    }}
                />
            </Box>
            {fieldState.error ? (
                <FormHelperText>{fieldState.error.message}</FormHelperText>
            ) : (
                <FormHelperText>
                    Max file size: {humanFormat(maxSize, { scale: "binary", unit: "B" })}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default FileUploadField;
